:root {
  --bg-body: #282c34;
  --bg-base: #53cdda;
  --bg-light: #9bf5ff;
  --bg-dark: #009fb1;
  --bg-shadow:#226c75;
  --font-primary: aliceblue;
  --base-rem: 10px;
  --btn-num: #0091a1;
  --btn-act: #003384;
  --btn-eq: #845400;
  --btn-clear: #840000;
}
@font-face{
  font-family:'digital-clock-font';
  src: url('./ds-digital.ttf');
 }
#calculator {
  width: 100%;
  height: max(calc(100vh - var(--header-height) - var(--footer-height) - 4rem),390px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: none;
  justify-content: center;
  align-items: center;
  
}
#calculator #calc {
  width: min(400px,100%);
  background-color: var(--bg-base);
  border-radius: 5px;
}
#calculator #display {
  height: 5rem;
  line-height: 4rem;
  width: 95%; 
  font-family: 'digital-clock-font';
  background-color: var(--bg-body);
  color: aliceblue;
  margin: 1rem 1rem 0.5rem;
  border: 3px solid var(--bg-body);
  border-radius: 3px;
  font-size: 4rem;
  font-weight: 700;
  padding: 0.4rem;
  text-align: right;
  vertical-align: middle;
  overflow: scroll;
}

#calculator #controls {
  width: 95%;
  display: grid;
  grid-template:
    "l l l m m" 6rem
    "h i j n p" 6rem
    "e f g o q" 6rem
    "b c d r r" 6rem
    "a a k r r" 6rem / 1fr 1fr 1fr 1fr 1fr;
  gap: 0.2rem;
  margin: 0.5rem 1rem 1rem;
}
#calculator #zero {grid-area:a}
#calculator #one  {grid-area:b}
#calculator #two  {grid-area:c}
#calculator #three {grid-area:d}
#calculator #four {grid-area:e}
#calculator #five {grid-area:f}
#calculator #six  {grid-area:g}
#calculator #seven {grid-area:h}
#calculator #eight {grid-area:i}
#calculator #nine {grid-area:j}
#calculator #decimal {grid-area:k}
#calculator #clear {grid-area:l}
#calculator #back {grid-area:m}
#calculator #add {grid-area:n}
#calculator #subtract {grid-area:o}
#calculator #multiply {grid-area:p}
#calculator #divide {grid-area:q}
#calculator #equals {grid-area:r}

#calculator .button {
  height: 100%;
  width: 100%;
  margin: auto;
  color: var(--font-primary);
  font-size: 3rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
}

#calculator .button:active, .clicked {
  position: relative;
  top: 0.2rem;
  left: 0.1rem;
  box-shadow: none;
  background-color: var(--bg-light);
  color: var(--font-primary);
}

#calculator .btn-num {
  background-color: var(--btn-num);
}

#calculator .btn-act {
  background-color: var(--btn-act);
}

#calculator .btn-clear {
  background-color: var(--btn-clear);
}

#calculator .btn-eq {
  background-color: var(--btn-eq);
}