
#visual-5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  font-family: 'Times New Roman', Times, serif;
}
#visual-5 #graph {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: var(--font-primary);
}
#visual-5 #graph svg {
  background-color: var(--welcome-color);
  color: var(--font-primary);
}
#visual-5 #graph svg g text{
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  fill: white;
}
#visual-5 #graph text.legend-text {
  fill: white;
  font-size: 1.6rem;
}
#visual-5 #title {
  width: 100%;
  font-size: 3rem;
  text-align: center;
  margin: 2rem auto 0;
}
#visual-5 #description {
  padding-top: 0.5rem;
  font-size: 2rem;
  text-align: center;
}
#visual-5 #tooltip {
  padding: 1rem;
  display: none;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: var(--font-tooltip);
  background-color:rgba(29, 32, 34, 0.8);
  border-radius: 3px;
  box-shadow: 0 0 1rem 1rem hsla(0, 0%, 0%, 0.50);
  position: absolute;
  z-index: 999;
  font-size: 1.4rem;
}
#visual-5 ul#vis-5-nav {
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
}
#visual-5 ul#vis-5-nav > li{
  margin: 0 0.5rem;
}

#visual-5 #legend {
  margin: 0.5rem auto;
}

