:root {
  --green: rgb(25, 135, 84);
  --gray: rgb(108, 117, 125);
  --red: rgb(220, 53, 69);
  --orange: rgb(255, 193, 7);
  --blue: rgb(13, 202, 240);
}
#miner{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
#miner-header {
  height: 20rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:3px;
}

#miner-header h1 {
  font-size: 4rem;
}
#miner-header h1.win {
  color: var(--green)
}
#miner-header h1.fail {
  color: var(--red)
}

#miner-counters {
  font-size: larger;
  margin: 0 auto;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr min-content;
}

#sf {
  font-size: larger;
  margin: 0 auto;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: minmax(10rem,max-content) 1fr;
}
#sf label, #sf input {
  display: block;
  line-height: 2.4rem;
  font-size: medium;
}
#sf label {
  text-align: right;
}
#sf label span.error-message {
  color: var(--red);
  font-size: small;
}
#sf input {
  border-radius: 4px;
}
#sf input[type="submit"] {
  width: 50%;
  background-color: var(--gray);
  color: var(--font-primary);
  font-weight: 500;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  border: 1px solid var(--gray);
}
#sf input[type="submit"]:active {
  background-color: var(--font-primary);
  color: var(--gray);
}

#miner-field {
  display: grid;
  gap: 1px;
}
.cell {
  width: 2.8rem;
  aspect-ratio: 1 / 1;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cell.close,.cell.bomb {
  background-color: var(--gray);
}

.cell.boom {
  background-color: var(--red);
}

.cell.open {
  background-color: var(--green);
}

.cell.flag {
  background-color: var(--orange);
}

.cell.neighbour {
  background-color: var(--blue);
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #343a40;
  font-weight: 700;
}

