:root {
    --quotebox-color: #333369;
}

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#quote-container {
  font-size: 1.6rem;
  background-color: var(--quotebox-color);
  height: calc(100vh - var(--footer-height) - var(--header-height));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#quote-box {
  min-height: 25rem;
  width: min(50rem,90%);
  background-color: #ffffff;
  margin: auto auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: var(--quotebox-color);
  padding: 3rem
}
#text {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  text-indent: -1em;
}
#text::before {
  content: '"';
  font-size: 4rem;
  font-weight: 900;
}
#author {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: right;
  padding: 1rem 0;
}
#author::before {
  content: ' - ';
}
#buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: auto;
}

#twitter {
  width: 50px;
  height: 42px;
  background-color: var(--quotebox-color);
  color: var(--quotebox-color);
 -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 248 204'%3E%3Cpath fill='%23fbf' d='M221.95 51.29c.15 2.17.15 4.34.15 6.53 0 66.73-50.8 143.69-143.69 143.69v-.04c-27.44.04-54.31-7.82-77.41-22.64 3.99.48 8 .72 12.02.73 22.74.02 44.83-7.61 62.72-21.66-21.61-.41-40.56-14.5-47.18-35.07 7.57 1.46 15.37 1.16 22.8-.87-23.56-4.76-40.51-25.46-40.51-49.5v-.64c7.02 3.91 14.88 6.08 22.92 6.32C11.58 63.31 4.74 33.79 18.14 10.71c25.64 31.55 63.47 50.73 104.08 52.76-4.07-17.54 1.49-35.92 14.61-48.25 20.34-19.12 52.33-18.14 71.45 2.19 11.31-2.23 22.15-6.38 32.07-12.26-3.77 11.69-11.66 21.62-22.2 27.93 10.01-1.18 19.79-3.86 29-7.95-6.78 10.16-15.32 19.01-25.2 26.16z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 248 204'%3E%3Cpath fill='%23fbf' d='M221.95 51.29c.15 2.17.15 4.34.15 6.53 0 66.73-50.8 143.69-143.69 143.69v-.04c-27.44.04-54.31-7.82-77.41-22.64 3.99.48 8 .72 12.02.73 22.74.02 44.83-7.61 62.72-21.66-21.61-.41-40.56-14.5-47.18-35.07 7.57 1.46 15.37 1.16 22.8-.87-23.56-4.76-40.51-25.46-40.51-49.5v-.64c7.02 3.91 14.88 6.08 22.92 6.32C11.58 63.31 4.74 33.79 18.14 10.71c25.64 31.55 63.47 50.73 104.08 52.76-4.07-17.54 1.49-35.92 14.61-48.25 20.34-19.12 52.33-18.14 71.45 2.19 11.31-2.23 22.15-6.38 32.07-12.26-3.77 11.69-11.66 21.62-22.2 27.93 10.01-1.18 19.79-3.86 29-7.95-6.78 10.16-15.32 19.01-25.2 26.16z'/%3E%3C/svg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat; 
  background-repeat:no-repeat;

}

#new_quote {
  background-color: var(--quotebox-color);
  border-radius: 3px;
  border: none;
  color: #ffffff;
  padding: 0.2rem 1rem;
  font-size: 1.6rem;
  font-weight: 400;
}