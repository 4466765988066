:root {
  --power-green: #17a71b;
  --pad-size: 8rem;
}
#drum {
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--footer-height) - 4rem);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: none;
  justify-content: center;
  align-items: center;
  color: var(--welcome-color);
}
#drum #drum-machine {
  height: max-content;
  background-color: var(--bg-base);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: none;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 1rem 1rem var(--bg-shadow);
  padding: 2rem;
}

#drum #controls {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: none;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 2rem;
}
#drum .row {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: none;
  justify-content: space-evenly;
  align-items: center;
}
#drum #display {
  width: 30rem;
  height: 5rem;
  background-color: var(--bg-light);
  color: var(--welcome-color);
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  border: 2px solid var(--bg-body);
  border-radius: 3px;
}

#drum #power-switch {
  width: 10rem;
  height: 5rem;
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  border: 2px solid var(--bg-body);
  border-radius: 3px;
  padding: auto 0;
  cursor: pointer;
}
#drum #power-switch.on {
  background-color: var(--power-green);
  color: var(--header-primary);
}
#drum #power-switch.off {
  background-color: var(--header-primary);
  color: var(--power-green);
}
#drum #vol {
  width: 80%; /* Full-width */
  background: var(--font-primary); /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.6; /* Set transparency (for mouse-over effects on hover) */
  transition: opacity .2s;
  cursor: pointer;
}
#drum #pads {
  height: 65%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(3,1fr);
  gap: 2rem;
}

#drum .drum-pad {

  width: 100%;
  height: 100%;
  margin: auto;
  background-color: var(--bg-dark);
  color: var(--bg-body);
  box-shadow: 0.2rem 0.2rem 0.5rem 0.5rem var(--bg-body);
  font-size: 4rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

#drum .drum-pad:active, .clicked {
  position: relative;
  top: 0.3rem;
  left: 0.3rem;
  box-shadow: none;
  background-color: var(--bg-light);
  color: var(--font-primary);
}
