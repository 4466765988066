:root {
  --font-graph: rgb(51, 51, 51);
  --dot-good:rgba(31, 118, 180, 0.7);
  --dot-bad:rgba(255, 127, 14, 0.7);
}

#visual-2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

#visual-2 #graph {
  color: var(--font-primary);
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  font-size: 1.6rem;
}
#visual-2 #title {
  width: 100%;
  text-align: center;
}
#visual-2 #title h1 {
  font-family: 'Times New Roman', Times, serif;
  margin-top: 0.5rem;
  font-weight: 500;
}
#visual-2 #title p {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
}
#visual-2 #graph svg {
  background-color: var(--welcome-color);
  color: var(--font-primary);
}
#visual-2 #graph svg g text{
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.6rem;
  color: var(--font-primary);
}
#visual-2 text.legend {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  fill: #fff;
}
#visual-2 #tooltip {
  display: none;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items:flex-start;
  background-color:aliceblue;
  border-radius: 3px;
  box-shadow: 0 0 1rem 1rem #a2b8c892;
  position: absolute;
  z-index: 999;
  font-size: 1.4rem;
  padding: 1rem;
  color: var(--font-graph);
}

#visual-2 #tooltip-doping {
  margin-top: 1rem;
}
