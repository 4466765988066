@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
:root {
  --header-primary: #ae4040;
  --header-secondary:#4a4a4a;
  --welcome-color: #4a4a4a;
  --projects-color: #333369;
  --contacts-color: var(--header-secondary);
  --font-primary: white;
  --font-secondary: var(--header-primary);
  --header-height: 70px;
  --footer-height: 70px;
  --width1: 1240px;
  --width2: 840px;
  --width3: 420px;
  --proj-title-height: 40px;
  --base-rem: 10px;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: -apple-system, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: var(--base-rem);
  font-weight: 400;
  color: var(--font-primary);
  scroll-padding-top: var(--header-height);
  scroll-behavior: smooth;
}
body {
  font-size: 1.6rem;
  background-color: var(--welcome-color)
}

#header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--header-primary);
  background-repeat: no-repeat;
}
#navbar {
  display: absolute;
  top: 0;
  right: 0;
  margin-right: 2rem;
  height: 100%;
}
#navbar ul {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:flex-end;
  align-content: center;
  list-style: none;
}
.nav-link {
  display: block;
  height: 100%;
}
.nav-link a {
  display: block;
  width: 100%;
  font-size: 2rem;
  color: var(--font-primary);
  text-decoration: none;
  height: 100%;
  line-height: 70px;
  padding: 0 2rem;
  
}
.nav-link:hover{
  background-color: var(--header-secondary);
}

main {
  margin-top: var(--header-height);
  max-height: fit-content;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}
#welcome-section {
  width: 100%;
  height: calc(100vh - var(--header-height));
  min-height: 10rem;
  background-color: var(--welcome-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#welcome-section h1 {
  font-size: 6rem;
  color: var(--font-primary);
}
#welcome-section p {
  font-size: 3rem;
  font-weight: 100;
  letter-spacing: .1rem;
  color: var(--font-secondary);
}

#projects {
  width: 100%;
  background-color: var(--projects-color);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
#projects header {
  font-size: 4rem;
  display: block;
  margin: 1rem auto 2rem;
  padding: 1rem;
  border-bottom: 4px solid var(--font-primary) ;
}
#projects-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(350px,1fr));
  gap: 2rem;
  margin: 2rem 10rem;

}
.project {
  border-radius: 4px;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-color: var(--header-secondary);
}
.project a {
  text-decoration: none;
}
.project:hover figcaption p {
  color: var(--header-primary);
}
.project img {
  width: 100%;
  height: calc(100% - var(--proj-title-height) + 2px);
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}
.project figcaption {
  display: block;
  width: 100%;
  height: var(--proj-title-height);
  border-radius: 0 0 4px 4px;
  color: var(--font-primary);
  text-align: center;
  
}
.project figcaption p {
  line-height: var(--proj-title-height);
}

.more_projects {
  width: 20rem;
  height: 5rem;
  margin: 2rem;
  text-align: center;
  vertical-align: middle;
  background-color: var(--header-primary);
  border-radius: 4px;
}
.more_projects:hover {
  background-color: var(--header-secondary);
}
.more_projects a {
  line-height: 5rem;
  color: var(--font-primary);
  text-decoration: none;
}
#contacts-section {
  width: 100%;
  height: max(200px, calc(100vh - var(--header-height) - var(--footer-height)));
  background-color: var(--contacts-color);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
#contacts-section header {
  font-size: 4rem;
  margin-bottom: 1rem;
}
#contacts-section p {
  font-size: 2rem;
  font-weight:lighter;
  margin-bottom: 3rem;
}
.social-icons a svg {
  color: var(--font-primary);
  font-size: 3rem;
  margin: 0 0.5rem;
}
.social-icons a {
  text-decoration: none;
  letter-spacing: 1rem;
}

footer {
  width: 100%;
  height: var(--footer-height);
  border-top: 4px solid var(--header-primary);
  background-color: var(--header-secondary);
  font-size: 2rem;
  color: var(--font-primary);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 2rem;
}
footer p {
  text-align: center;
}
footer a {
  text-decoration: none;
  color: var(--font-primary);
}

@media (max-width: 520px) {
  html {
      font-size: 8px;
  }
  #navbar {
      display: block;
      margin: 0 auto;
  }
  #navbar ul {
      justify-content:space-evenly;   
  }
  
}