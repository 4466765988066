
#visual-3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  font-family: 'Times New Roman', Times, serif;
}
#visual-3 h1 {
  font-weight: 500;
  margin: 0.5rem;
}
#visual-3 #graph {
  display: block;
}
#visual-3 #graph svg {
  background-color: var(--welcome-color);
  color: var(--font-primary);
}
#visual-3 #graph svg g text{
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.6rem;
}

#visual-3 #tooltip {
  padding: 1rem;
  display: none;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: var(--font-primary);
  background-color:rgba(29, 32, 34, 0.8);
  border-radius: 3px;
  box-shadow: 0 0 1rem 1rem hsla(0, 0%, 0%, 0.50);
  position: absolute;
  z-index: 999;
  font-size: 2rem;
}

#visual-3 #description {
  padding-top: 0.5rem;
  font-size: 2rem;
  text-align: center;
}
