:root {
  --header-primary: #ae4040;
  --header-secondary:#4a4a4a;
  --welcome-color: #4a4a4a;
  --projects-color: #333369;
  --contacts-color: var(--header-secondary);
  --font-primary: white;
  --font-secondary: var(--header-primary);
  --header-height: 70px;
  --footer-height: 140px;
  --width1: 1240px;
  --width2: 840px;
  --width3: 420px;
  --proj-title-height: 40px;
  --base-rem: 10px;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: var(--base-rem);
  color: var(--font-primary);
  scroll-padding-top: var(--header-height);
}
body {
  font-size: 1.6rem;
}
