:root {
  --bg-rect: #33bb5c;
  --bg-light: #ffffff;
  --font-graph: rgb(51, 51, 51);
}

#visual-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

#visual-1 #graph {
  color: var(--font-graph);
  font-family: 'Times New Roman', Times, serif;
  background-color: var(--bg-light);
}

#visual-1 #title {
  width: 100%;
  font-size: 3rem;
  text-align: center;
  margin-top: 1rem;
}

#visual-1 .bar {
  background-color: var(--bg-rect);
  border: none;
}
#visual-1 #tooltip {
  width: 180px;
  height: 80px;
  display: none;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color:aliceblue;
  border-radius: 3px;
  box-shadow: 0 0 1rem 1rem #a2b8c892;
  position: absolute;
  z-index: 999;
  font-size: 2rem;
}

#visual-1 #description {
  width: min(900px,100%);
  padding: 1rem;
  text-align: right;
}