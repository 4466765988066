:root {
  --bg-timer: #282c34;
  --font-enable: rgb(113, 155, 189);
  --font-disable: rgb(113, 113, 113);
}

#timer {
  color: var(--font-enable);
  font-weight: 700;
  background-color: var(--bg-timer);
  height: max(calc(100vh - var(--header-height) - var(--footer-height)),400px);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
#timer #pomodoro {
  width: min(400px,100%);
  height: min(400px,100%);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

#timer #pomodoro>header{
  font-size:6rem;
  color: var(--font-enable);
}

#timer #controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

#timer .time-setter {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

#timer .time-setter>header {
  font-size:4rem;
}

#timer .setter-nums {
  height: 5rem;
  width: 5rem;
  font-size:4rem;
  text-align: center;
  vertical-align: middle;
  color: var(--font-enable);
}
#timer .setter-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

#timer button {
  height: 5rem;
  width: 5rem;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat; 
  background-repeat:no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: var(--font-enable);
}

#timer .controll-button.increment {
  cursor: pointer;
  mask-position: 0 -2px;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z'/%3E%3C/svg%3E");
   mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z'/%3E%3C/svg%3E");
}

#timer .controll-button.decrement {
  cursor: pointer;
  mask-position: 0 -2px;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M200-450v-60h560v60H200Z'/%3E%3C/svg%3E");
   mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M200-450v-60h560v60H200Z'/%3E%3C/svg%3E");
}
#timer .controll-button:active {
  background-color: red;
}
#timer .controll-button.increment.inactive, .controll-button.increment.inactive:active  {
  background-color: var(--font-disable);
}
#timer .controll-button.decrement.inactive, .controll-button.decrement.inactive:active  {
  background-color: var(--font-disable);
}
#timer #timer-main {
  margin-top: 5rem
}
#timer #time-left {
  font-size: 10rem;
  color: var(--font-enable);
}

#timer #timer-label {
  font-size: 6rem;
  text-transform: capitalize;
  text-align: center;
  color: var(--font-enable);
}

#timer #timer-buttons {
  text-align: center;
}

#timer #start_stop {
  cursor: pointer;
  mask-position: -5px -5px;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M320-203v-560l440 280-440 280Zm60-280Zm0 171 269-171-269-171v342Z'/%3E%3C/svg%3E");
   mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M320-203v-560l440 280-440 280Zm60-280Zm0 171 269-171-269-171v342Z'/%3E%3C/svg%3E");
}

#timer #start_stop.start{
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M525-200v-560h235v560H525Zm-325 0v-560h235v560H200Zm385-60h115v-440H585v440Zm-325 0h115v-440H260v440Zm0-440v440-440Zm325 0v440-440Z'/%3E%3C/svg%3E");
   mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='3rem' width='3rem' viewBox='0 -960 960 960'%3E%3Cpath d='M525-200v-560h235v560H525Zm-325 0v-560h235v560H200Zm385-60h115v-440H585v440Zm-325 0h115v-440H260v440Zm0-440v440-440Zm325 0v440-440Z'/%3E%3C/svg%3E");
}

#timer #reset{
  cursor: pointer;
  margin-left: 1rem;
  width: 4rem;
  height: 4rem;
  mask-position: 0px 0px;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 -960 960 960'%3E%3Cpath d='M480-80q-75 0-140.5-28T225-185q-49-49-77-114.5T120-440h60q0 125 87.5 212.5T480-140q125 0 212.5-87.5T780-440q0-125-85-212.5T485-740h-23l73 73-41 42-147-147 147-147 41 41-78 78h23q75 0 140.5 28T735-695q49 49 77 114.5T840-440q0 75-28 140.5T735-185q-49 49-114.5 77T480-80Z'/%3E%3C/svg%3E"); 
   mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2rem' width='2rem' viewBox='0 -960 960 960'%3E%3Cpath d='M480-80q-75 0-140.5-28T225-185q-49-49-77-114.5T120-440h60q0 125 87.5 212.5T480-140q125 0 212.5-87.5T780-440q0-125-85-212.5T485-740h-23l73 73-41 42-147-147 147-147 41 41-78 78h23q75 0 140.5 28T735-695q49 49 77 114.5T840-440q0 75-28 140.5T735-185q-49 49-114.5 77T480-80Z'/%3E%3C/svg%3E");
}

#timer #start_stop:active, #reset:active {
  background-color: red;
}

@media (max-width: 420px) {
  #timer #controls {
    flex-direction: column;
    gap: 3rem;
  }
}